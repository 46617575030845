import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { AccountInvitations } from '@patrianna/shared-patrianna-types/store/AccountInvitationsModule'
import type { AccountInvitationsState } from 'src/store/types/AccountInvitationsModule'

const initialState: AccountInvitationsState = {
  invitations: null,
  policies: null,
}

export const accountInvitationsSlice = createSlice({
  name: 'accountInvitations',
  initialState,
  reducers: {
    setAccountInvitations: (state, action: PayloadAction<AccountInvitations>) => {
      state.invitations = action.payload.invitations
      state.policies = action.payload.policies
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof accountInvitationsSlice.actions>>
export const { actions } = accountInvitationsSlice
