import type { NotifyResponses } from '@patrianna/shared-patrianna-types/websocket/notifications'
import type { TypedThunk } from 'store/types'
import { WSinstance } from 'services/websocket'
import wsNotifyHandler from 'services/websocket/wsNotifyHandler'

export const initSocketListeners =
  (): TypedThunk =>
  (dispatch, getState, { errorHandler }) => {
    const socketRoot = WSinstance.getInstance()

    if (!socketRoot || socketRoot?.readyState !== WebSocket.OPEN) {
      // eslint-disable-next-line
      console.log('ws logs:  initSocketListeners when socket closed')
      // sentry logs
    } else {
      socketRoot.onmessage = (resp) => {
        try {
          const event = JSON.parse(resp.data)
          // eslint-disable-next-line
          console.log(`ws logs:  message ${event.body.type}`, event)

          // notify events
          if (!event.status) {
            dispatch(wsNotifyHandler(event as { body: NotifyResponses }))

            return null
          }

          // resp events
          const { status } = event

          // handle all response errors from websocket
          if (status.errorCode !== 'err_ok') {
            dispatch(errorHandler(event, {}))
          }
        } catch (error) {
          // eslint-disable-next-line
          console.log('WSinstance.node.onmessage - error', error)
        }

        return null
      }

      socketRoot.onclose = (event) => {
        // eslint-disable-next-line
        console.log('ws logs:  onclose event', event)

        WSinstance.clear()

        // do not reconnect browser tab is not active
        if (document.hidden) {
          return null
        }

        // do not reconnect when socket disconnected by user using WSinstance.disconnect()
        if (event.code === 4444) {
          return null
        }

        // reconnect
        WSinstance.connect(dispatch)

        return null
      }

      socketRoot.onerror = (error) => {
        // eslint-disable-next-line
        console.log('ws logs: onerror event', error)
      }
    }
  }
