import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { FreeSpinType } from '@patrianna/shared-patrianna-types/store/SlotGameFlowModule'
import type { FreeSpinsState } from 'store/types/FreeSpinsModule'

const initialState: FreeSpinsState = {
  freeSpins: [],
}

export const freeSpinsSlice = createSlice({
  name: 'freeSpins',
  initialState,
  reducers: {
    saveFreeSpins: (state, action: PayloadAction<{ freeSpins: FreeSpinType[] }>) => {
      state.freeSpins = [...state.freeSpins, ...action.payload.freeSpins]
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof freeSpinsSlice.actions>>
export const { actions } = freeSpinsSlice
