import type { GetAccountFreeSpinsRequest } from '@patrianna/shared-patrianna-types/websocket/requests'
import type { GetAccountFreeSpinsResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'store/types'
import { waitingForSessionDialogsData } from '../sessionDialogs/actions'
import { actions } from './slice'

export const { saveFreeSpins } = actions

export const getUserFreeSpins =
  (isScEnabled: boolean): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    const data: GetAccountFreeSpinsRequest = {
      type: 'GetAccountFreeSpinsRequest',
      currency: isScEnabled ? ['SC', 'GC'] : ['GC'],
    }

    gateway
      .emit<GetAccountFreeSpinsResponse>(data)
      .then((respBody) => {
        dispatch(saveFreeSpins({ freeSpins: respBody?.data }))
        dispatch(waitingForSessionDialogsData({ freeSpins: true }))
      })
      .catch((err) => {
        dispatch(errorHandler(err, data))
      })
  }
