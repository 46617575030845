import type { SetPlayedGamesResponse } from '@patrianna/shared-patrianna-types/websocket/response'
import type { TypedThunk } from 'src/store/types'
import { actions } from './slice'
import type { ServiceDependenciesOptions } from 'src/services'

export const { savePlayedLottos, setPlayedGames, updateGame, updatePlayedLotto, clearPlayedGames } = actions

export function getPlayedGamesRequest(gateway: ServiceDependenciesOptions['gateway']) {
  return gateway.emit<SetPlayedGamesResponse>({
    type: 'GetOptInProductsRequest',
  })
}

export const fetchAllGamesHistory =
  (): TypedThunk =>
  (dispatch, getState, { gateway, errorHandler }) => {
    getPlayedGamesRequest(gateway)
      .then((body) => {
        dispatch(setPlayedGames({ games: body.products }))
      })
      .catch((err) => {
        dispatch(
          errorHandler(err, {
            type: 'GetOptInProductsRequest',
          })
        )
      })
  }
