import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { GameProduct } from '@patrianna/shared-patrianna-types/store/GameModule'
import type { LottoPlayedGame } from '@patrianna/shared-patrianna-types/store/PlayedGamesModule'
import { compareByDate } from '@patrianna/shared-utils/date'
import type { PlayedGamesState } from 'store/types/PlayedGamesModule'

const initialState: PlayedGamesState = {
  acceptedLottos: null,
  closedLottos: null,
  games: null,
}

export const playedGamesSlice = createSlice({
  name: 'playedGames',
  initialState,
  reducers: {
    // @TODO will remove
    savePlayedLottos: (
      state,
      action: PayloadAction<{
        accepted: Array<LottoPlayedGame>
        closed: Array<LottoPlayedGame>
      }>
    ) => {
      state.acceptedLottos = action.payload.accepted
      state.closedLottos = action.payload.closed
    },
    // @TODO will remove
    updatePlayedLotto: (state, action: PayloadAction<{ game: LottoPlayedGame }>) => {
      const { game } = action.payload
      const { acceptedLottos, closedLottos } = state

      // remove from upcoming
      const upcoming = acceptedLottos && acceptedLottos.filter((i) => i.id !== game.id)
      // add to past
      const past = closedLottos ? [...closedLottos, game] : [game]

      state.acceptedLottos = upcoming?.slice().sort((a, b) => compareByDate(b.drawDate, a.drawDate))
      state.closedLottos = past?.slice().sort((a, b) => compareByDate(b.drawDate, a.drawDate))
    },

    // @TODO will remove
    updateGame: (state, action: PayloadAction<{ game: LottoPlayedGame }>) => {
      const { acceptedLottos } = state
      const hasGame = acceptedLottos.find((it) => it.id === action.payload.game.id)

      let games
      if (hasGame) {
        games = acceptedLottos.map((it) => {
          if (it.id === hasGame.id) {
            return { ...action.payload.game }
          }

          return it
        })
      } else {
        games = [{ ...action.payload.game }, ...acceptedLottos]
      }

      state.acceptedLottos = games
    },

    clearPlayedGames: () => initialState,

    setPlayedGames: (state, action: PayloadAction<{ games: Array<GameProduct> }>) => {
      state.games = action.payload.games
    },
  },
})

export type ActionsTypes = ReturnType<InferValueTypes<typeof playedGamesSlice.actions>>

export const { actions } = playedGamesSlice
