import { loadScript } from '@patrianna/shared-utils/loadScript'

type FBResponse = {
  email: string
  name: string
  id: string
  error: { message: string }
}

type FBLoginResponse = {
  authResponse: {
    accessToken: string
  }
}

export const initFacebookApp = (appId: string) =>
  new Promise((res, rej) => {
    loadScript('https://connect.facebook.net/en_US/sdk.js')
      .then(() => {
        if (window.FB) {
          window.FB.init({
            appId,
            autoLogAppEvents: true,
            cookie: true,
            status: true,
            xfbml: true,
            version: 'v9.0',
          })
          res(FB)
        }
      })
      .catch((err) => {
        rej(err)
      })
  })

export const getFacebookUserData = (token: string) =>
  new Promise<FBResponse>((res, rej) => {
    window.FB.api(
      '/me',
      {
        access_token: token,
        fields: 'email,name,id',
      },
      (data: FBResponse) => {
        if (data.error) {
          rej(data.error)
        } else {
          res(data)
        }
      }
    )
  })

export const fbLoginViaSDK = () =>
  new Promise<FBLoginResponse>((res, rej) => {
    window.FB.login(
      (result) => {
        if (result.authResponse) {
          res(result as FBLoginResponse)
        } else {
          rej(result.status)
        }
      },
      {
        scope: 'email,public_profile',
      }
    )
  })
